import React, { useState } from 'react';
import FileSaver from 'file-saver';
import ExcelExport from 'jsx/elements/buttons/ExcelExport';
import { parseDate } from 'utils/date';
import { api } from 'api';
import { makeQueryFromState } from 'helpers';

export const ExcelExportButton = ({ state }) => {
  const [loading, setLoading] = useState(false);

  async function downloadXlsx() {
    setLoading(true);
    try {
      const blob = await api.getSearchExcel(makeQueryFromState(state));
      if (!blob.error) {
        FileSaver.saveAs(blob, `Creditinfo-Fjölmiðlar-${parseDate(new Date())}.xlsx`);
      }
    } catch (e) {}
    setLoading(false);
  }

  return (
    <ExcelExport
      onClick={downloadXlsx}
      disabled={state.monitorData && state.monitorData.totalCount === 0}
      loading={loading}
    />
  );
};
